<template>
  <div class="sec5 py-5" v-if="$route.name!=='FormPage'">
    <div class="container py-4">
      <div class="d-flex align-items-center flex-wrap justify-content-between">
        <h3 class="white pb-3 pb-md-0">Остались вопросы? <br>
          С удовольствием ответим!</h3>

        <router-link :to="{name: 'FormPage', params:{formType: 'contact'}}" class="btn">Связаться с нами <i><img src=img/arrow-forward-24-px.svg alt=""></i></router-link>

      </div>
    </div>
  </div>
  <footer class="py-5">
    <div class="container  text-sm-start text-center">
      <div class="row pb-3">
        <div class="col-lg-3 col-md-4">
          <router-link :to="{name: 'Home'}">
          <img class="mb-sm-5 mb-3 footer_logo" src="img/footer_logo.png" alt="">
          </router-link>
          <div class="d-lg-block d-none copyright" style="margin-top: 32px">
            © 2022 TurkishHelper
          </div>
<!--          <p>-->
<!--            Turkish Helper LCC (3rd floor) <br>-->
<!--            Dhanmondi, Dhaka 1209, Stambul-->
<!--          </p>-->
        </div>
        <div class="col-lg-3 col-md-4 mb-4">
          <router-link :to="{name: 'Services'}" class="my-2 d-inline-block" > Услуги</router-link><br>
          <router-link :to="{name: 'Purchases'}" class="mb-2 d-inline-block" >Покупки онлайн</router-link><br>
          <router-link :to="{name: 'Cooperation'}" class="mb-2 d-inline-block" > Сотрудничество</router-link><br>
<!--          <a class="mb-4 d-inline-block" href=""> Контакты</a>-->

        </div>
        <div class="col-lg-3 col-md-4 mb-4">
          <a href="1.pdf" target="_blank" class="my-2 d-inline-block" > Публичная оферта</a><br>
          <a href="2.pdf" target="_blank" class="mb-2 d-inline-block" >Политика конфиденциальности</a><br>
          <a href="3.pdf" target="_blank" class="mb-2 d-inline-block" > Политика использования cookie</a><br>
        </div>
        <div class="col-lg-3 d-flex flex-column justify-content-between">
        <div>
          <a href="tel:+79169009932" class="my-2 d-block"><i class="pe-3"><img src="img/icon-phone.svg" alt=""></i>+7 916 900 9932</a>
          <a href="mailto:moscow@turlly.com" class="mb-2 d-block"><i class="pe-3"><img src="img/icon-email.svg" alt=""/></i>moscow@turlly.com</a>
        </div>
        <div class="d-flex payment-icons justify-content-sm-start justify-content-center my-2">
          <img  src="img/pay1.svg" alt="">
          <img src="img/pay2.svg" alt="">
          <img src="img/pay3.svg" alt="">
          <img src="img/pay4.svg" alt="">
        </div>
          <div class="d-block d-lg-none copyright pt-3" >
            © 2022 TurkishHelper
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer-component",

}
</script>

<style scoped>
.copyright{
  font-size: 15px;
}
footer a{
  transition: .5s;
}
footer a:hover{
color: #fff;
}
</style>
<script setup>
</script>