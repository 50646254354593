<template>
  <div class="main">
    <header-component/>
    <router-view/>
    <footer-component/>
  </div>
</template>

<script>

import HeaderComponent from "@/components/header-component";
import FooterComponent from "@/components/footer-component";
// import Home from "@/views/Home";

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
  }
}
</script>

<style>
@import "./assets/css/bootstrap.min.css";
@import "./assets/css/main.css";
</style>
