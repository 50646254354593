import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/services',
        name: 'Services',
        component: ()=> import('../views/Services.vue')

    },
    {
        path: '/purchases',
        name: 'Purchases',
        component: ()=> import('../views/Purchases.vue')

    },
    {
        path: '/cooperation',
        name: 'Cooperation',
        component: ()=> import('../views/Cooperation.vue')
    },
    {
        path: '/form/:formType',
        name: 'FormPage',
        props: true,
        component: ()=> import('../views/FormPage.vue')

    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
