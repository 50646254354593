<template>
  <header>
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <router-link :to="{name: 'Home'}" class="logo"><img src="img/logo.png" alt=""></router-link>
        <div class="d-flex align-items-center">
          <router-link :to="{name: 'Services'}" class="black d-none d-lg-block mx-4" :class="{'active': currentRout === 'Services'}">Услуги</router-link>
          <router-link :to="{name: 'Purchases'}" class="black d-none d-lg-block mx-4" :class="{'active': currentRout === 'Purchases'}">Покупки онлайн</router-link>
          <router-link :to="{name: 'Cooperation'}" class="black d-none d-lg-block mx-4" :class="{'active': currentRout === 'Cooperation'}">Сотрудничество</router-link>
          <router-link :to="{name: 'FormPage', params:{formType: 'contact'}}" class="black d-none d-lg-block mx-4" :class="{'active': currentRout === 'FormPage'}">Контакты</router-link>
          <div class="d-lg-none">
            <a class="menu" @click="modalState =!modalState">
              <img v-if="!modalState"  src="img/burger_icon.svg" alt="">
              <img v-else src="img/close_icon.svg" alt="">
            </a>
          </div>
        </div>
      </div>
      <div @click="modalState =!modalState" v-if="modalState" class="my_modal d-flex justify-content-center align-items-center">
        <div class="d-flex flex-column my_modal_body text-center  pt-2">
          <router-link :to="{name: 'Services'}" class="black  d-lg-block m-2" :class="{'active': currentRout === 'Services'}">Услуги</router-link>
          <router-link  :to="{name: 'Purchases'}" class="black d-lg-block m-2" :class="{'active': currentRout === 'Purchases'}">Покупки онлайн</router-link>
          <router-link  :to="{name: 'Cooperation'}" class="black  d-lg-block m-2" :class="{'active': currentRout === 'Cooperation'}">Сотрудничество</router-link>
          <router-link :to="{name: 'FormPage', params:{formType: 'contact'}}" class="black my-3 mx-2" :class="{'active': currentRout === 'FormPage'}"> Контакты </router-link>
        </div>
      </div>


    </div>
  </header>
</template>

<script>
export default {
  name: "header-component",
  data(){
    return{
      modalState: false
    }
  },
  computed:{
    currentRout(){
      return this.$route.name
    }
  },
  methods:{
    toggleModal() {
    },
  }
}
</script>

<style scoped>
  .my_modal{
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
    background: rgba(39, 39, 39, 0.85);

  }
  .my_modal_body{
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 0 77px 0 rgba(0, 0, 0, 0.06);
    max-width: 80%;
    width: 420px;
    font-size: 27px;
  }
</style>
